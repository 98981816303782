/*
Radiator Digital
http://www.radiatordigital.com
*/

//===================================================================//
// =====================  BASE VARIABLES   ======================== //
//=================================================================//


// Dimensions & Grid
// --------------------------------
$desktop: 1180px;
$tablet: 960px - 1px;
$mobile: 780px - 1px;
$columns: (100% / 12);
$dmargin: 20px / $desktop * 100%;
$mmargin: 10px / $mobile * 100%;
$oneThird:(100/3)*1%;
$twoThird:(100/3)*2%;

$max: 1920px;
$mw: 1300px;
$th: 1000px;
$cw: 900px;
$sw: 700px;
$section: 40px;
$sectionlarge: 60px;
$unit: (1/12)*100%;

// Variables Declaration
// --------------------------------
$font-stack: 'Quicksand',sans-serif, 'Raleway', sans-serif;
$font-stack2: 'Quicksand',sans-serif, 'Roboto', sans-serif;

$gbltxt-hover: #1565C0; 
$primary-color: #1565C0; 
$secondary-color: #efdb2a;
$text-color: #000;


// FONT SIZES
// --------------------------------
$body: 16px;
$bodyMobile: 14px;

$llargText:  30px;
$largText:   24px;
$mediumText: 20px;
$smallText:  16px;
$ssmallText: 14px;



// FONT SPACING
// --------------------------------
$font-space1: 1px;
$font-space2: 2px;
$font-space3: 3px;
$font-space-normal: normal;

// FONT-WEIGHT - $MAIN
// --------------------------------
$slight: 100;
$light: 300;
$regular: 400;
$medium: 500;
$sum-medium: 600;
$bold: 700;
$strong: 700;
$weightBlack: 900;


// Base Colours
// --------------------------------

$trans: transparent;

$white: #fff;
$black: #000;

$w: #fff;
$b: #000;


$ddgrey: #454545;
$dgrey: #888888;
$grey:  #dfdfdf;
$mgrey:  #ececec;
$lgrey:  #f7f7f7;

$dpurple: #34007b;


$danger: #ff0000;

$trans: transparent;


// Fx
// --------------------------------
$shadow:0px 0px 5px 0px #333;  // Global Box Shadow Style (if applicable)
$headShad:0px 0px 10px $black;

// Social
// --------------------------------
$social: (	
	//'facebook': $fb,
	//'twitter': $tw,
	//'pinterest': $pnt,	
	//'linkedin': $li, 
	//'instagram': $inst,
	//'youtube': $yt, 
	//'google-plus': $gplus,
	//'vk': $vk,
	//'weixin': $weixin,
	//'weibo': $weibo
);

$fb:  #38629f;
$tw:  #1fb0e2;
$pnt: #2c5d81;
$li:  #167cb8;
$inst: #d71d7a;

//  BREAK POINTS
//---------------------------------
$menu-break: 1110px;
$duelColumnBreak: 860px;
$gridBlocksBreak: 767px;
$gridBlocksBreak__mobile: 550px;












