*, *::before, *::after{
    @include box-sizing;
}

html.mm-blocking{
    overflow: visible;
}

body,
html,
header,
footer,
section,
article,
main,
nav{
    width: 100%;
    margin: 0;
    padding: 0;
}

input{max-width: 100%;}
blockquote{margin: 0;}


img{
    //display: block;
    height: auto;
    max-width: 100%;
}

a:focus {
    outline: none;
}

html, body {    
    @include font-size(16, 16);
    font-family: $font-stack;   
    font-weight: $regular;
    color: $text-color;
    overflow: auto;
    @include padding(0px);
    @include margin(0px);
    -webkit-font-smoothing: antialiased; 
    -webkit-text-size-adjust: 100%;  

    @media screen and (max-width: 768px) {
        font-size: $bodyMobile;
    }
}

body {
    overflow-x: hidden;    
}


// Heading sizes 

h1, h2, h3, h4, h5, h6 {    
    font-family: $font-stack;
    font-weight: $sum-medium;
    @include margin-all(0, 0, 20px, 0); 
    @include padding(0px);
    position: relative;     
}

h1 {@include font-size(36, 16);}
h2 { @include font-size(32, 16); }
h3 { @include font-size(24, 16); }
h4 { @include font-size(20, 16); }
h5 { @include font-size(18, 16); }
h6 { @include font-size(17, 16); }

a{
    text-decoration:none;    
    color: $gbltxt-hover;    

    &:hover{
        text-decoration:none;
        color: $gbltxt-hover;
    }
}

@include media(768px){
    
}

@media (min-width:768px) {
.modal-small {max-width:450px; width:100%;}
.modal-sm {max-width:600px; width:100%;}
.modal-md {max-width:800px; width:100%;}
.modal-lg {max-width:1160px; width:100%;}
}

