/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
    src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
 
@mixin matIcon($icon){
	&::before{
      @extend .material-icons;
      font-size:$body;
      content:$icon;
      text-rendering: optimizeLegibility;
  	}
}

