// Loop through theme Colours
//@each $name, $hex in $themes1 {.#{$name} & { color: $hex;}}

// Loop through theme Colours
//@each $name, $hex in $themesBG1 {.#{$name} & {background-color: $hex;}}

.color1{background-color: #ff0;}
.color2{background-color: #0f0;}

.blueBG{
    background-color:$primary-color;
    color:$w;
}
.dgreyBG{
    background-color:#7c7b7b;
    color: $w;
}
.yellowBG{
    background-color:#efdb2a;
    color:#444;
}

.wrapper {
    position: relative; 
    margin: 0 auto;
}

.gbl{
	@include padding-all(60px, null, 60px, null);
}

#window{
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #232831;
}

section{
    width: 100%;
    display: block;
}

.container{
    max-width:1330px;
    width:100%; 
    position:relative;
    margin: 0 auto;
    @include selfClearing;
}

.text-left{text-align:left;}
.text-right{text-align:right;}
.text-center{text-align:center;}

@include media(992px){
    .mob-hide{
        display: none !important;
    }
}

.shadow{
    @include box-shadow(0 0px 5px 0px rgba(0, 0, 0, 0.3));
}

@include media(1024px){
   .gbl{padding: 30px 0;}
}


@include media(768px){}

.pb0{padding-bottom:0px}
.pb10{padding-bottom:10px}
.pb20{padding-bottom:20px}
.pb30{padding-bottom:30px}
.pb40{padding-bottom:40px}
.pb50{padding-bottom:50px}
.pb60{padding-bottom:60px}

.pt0{padding-top:0px}
.pt10{padding-top:10px}
.pt20{padding-top:20px}
.pt30{padding-top:30px}
.pt40{padding-top:40px}
.pt50{padding-top:50px}
.pt60{padding-top:60px}

.mb0{margin-bottom:0px}
.mb10{margin-bottom:10px}
.mb20{margin-bottom:20px}
.mb30{margin-bottom:30px}
.mb40{margin-bottom:40px}
.mb50{margin-bottom:50px}
.mb60{margin-bottom:60px}

.mt0{margin-top:0px}
.mt10{margin-top:10px}
.mt20{margin-top:20px}
.mt30{margin-top:30px}
.mt40{margin-top:40px}
.mt50{margin-top:50px}
.mt60{margin-top:60px}


// FADE IN SECTIONS 
html.js{
    section{
        &:not(:nth-of-type(1)){
            @include transitions(opacity 2s, transform 1s);
            transform: translateY(150px);
            opacity:0;

            &.in-view{
                opacity: 1;
                transform: translateY(0);                
            } 
            &.vacancy-aplGrid{
                transform: translateY(0) skewY(-2deg);
            }           
        }        
    }
}

// Two Row Block 
.twoRow{
    @include padding(0);
    @include margin(0);
    list-style: none;
    @include selfClearing;
    

    li{
        //@include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        width: 50%; float: left;
        width: calc(50% - (15px));
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;

        &:nth-child(2n+2)   {margin-right: 0px;}
        &:nth-of-type(2n+1) {clear: left;}

        a{
            text-decoration: none;
        }
    }         
}

// Three Row Block 
.threeRow{
    @include padding(0);
    @include margin(0);
    list-style: none;
    @include selfClearing;
    

    li{
        @include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        width: 33.333%; float: left;
        width: calc(33.333% - 20px);
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;

        &:nth-child(3n+3)   {margin-right: 0px;}
        &:nth-of-type(3n+1) {clear: left;}

        a{
            text-decoration: none;
        }
    }         
}

// Four Image Feature
.fourRow{
    @include padding(0);
    @include margin(0);
    list-style: none;
    @include selfClearing;
    

    li{
        @include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        width: 25%; float: left;
        width: calc( 25% - (22.5px));
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;

        &:nth-child(4n+4) {margin-right: 0px;}
        &:nth-of-type(4n+1) {clear: left;}

        a{
            text-decoration: none;
        }
    }         
}

// Five Image Feature
.fiveRow{
    @include padding(0);
    @include margin(0);
    list-style: none;
    @include selfClearing;
    

    li{
        @include box-shadow(0 6px 30px -10px rgba(#000, 0.4) );
        width: 20%; float: left;
        width: calc( 20% - (24px));
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;

        &:nth-child(5n+5) {margin-right: 0px;}
        &:nth-of-type(5n+1) {clear: left;}

        a{
            text-decoration: none;
        }
    }         
}






