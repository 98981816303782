// Button Stylings for Cookies Noti bar

.cta{
    @include padding(10px 20px 10px 20px); 
    background-color: #fff; 
    border:0px; 
    outline: none;
    font-size: 16px; 
    color:#000; 
    display: inline-block; 
    text-align: center; 
    cursor: pointer;
    border-radius:4px;
    font-weight: $medium;

    &.orange-btn{
        background-color: #EF8F00;
        color:#fff;

        &:hover{
            background-color: #1565C0;             
        }
    }

    &.green-btn{
        background-color: #2FCE6F;
        color:#fff;

        &:hover{
            background-color: #00903A;             
        }
    }

    &.yellow-btn{
        background-color: #efdb2a;
        border: 2px solid #efdb2a;
        border-radius:25px;
        font-size: 20px;
        width:100%; max-width: 400px;
        @include padding(15px 50px 15px 50px); 

        &:hover{
            background-color: $w;
            border: 2px solid #232730;
            color:#000;
        }
    }

    &.full-cta{
        width: 100%;
    }
}












