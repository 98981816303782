.none{display: none;}

.site-header{
    //background-color: $w;
    @include padding-all(20px, 0, 10px, 0);
    position: relative;
    z-index: 1;

    .top-header{
        padding: 30px;
    }

    .cta{
        float: right;
        font-size: $ssmallText;
        font-weight: $medium;
        @include margin-all(10px, null, null, null);        
    }
    .call-us{
        float: right;        
        font-size: 30px;
        line-height: 44px;
        @include margin-all(10px, 0px, null, null);
        color:$w;
        font-weight:$medium;
        
        &:hover{
            color:$secondary-color;
        }        
    }

    @include media(576px){
        .top-header{
            padding: 30px 15px;
        }
        .logo{
            max-width: 150px;
            display: inline-block;
        }
        .cta{
            font-size: 12px;
            padding: 10px;
        }
        .call-us{
            font-size: 18px;                      
        }
    }
}

.banner-form{
    position: relative;
    @include padding-all(100px, 0, 100px, 0);

    .form-block{
        margin: 0 auto; text-align: center;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
        background: $w; border-radius:40px;
        @include padding-all(50px, 50px, 50px, 50px);
        
        .head-block{
            padding-bottom: 40px;

            h2{
                color: $b; 
            }
            h3{
                color: $b;                 
            }
        }

        @include media(768px){
            @include padding-all(30px, 15px, 30px, 15px);
        }
    }

    .quiz-component {

        .app-answer {
            background: $secondary-color; 
            @include padding(10px);
            color: $b; 
            font-size: 18px;
            cursor: pointer; 
            height: 80px; 
            line-height: 20px;
            margin-bottom: 30px;
            display: -webkit-box; 
            display: -ms-flexbox; 
            display: flex; 
            align-items: center; 
            justify-content: center;
            border-radius:20px;
        }
        
        .quiz-item {
            h3{
                color: $b; 
                padding-bottom: 40px;                 
            }
        }

        .laststep-form{
            padding: 20px; 
            background: #000; 
            border-radius: 10px;

            h4{
                margin-bottom: 10px; 
                @include font-size(28, 16);
                color: #fff;
                text-align: center;
                line-height: 38px;
                padding: 0 14%;
                letter-spacing: 1px;
            }

            p{
                color: $w;
                text-align: center;
                font-weight: $medium;

                &:last-child{
                    margin-bottom:0px;
                }
            }

            textarea{
                &.form-control{
                    height: 6.25rem;
                }
            }

            .form-block-checkbox{
                position: relative;
                padding-left: 20px;
                padding-bottom: 10px;

                .form-label{
                    color: #ccc;
                    font-size: 14px;
                    font-weight: $sum-medium;
                }

                input[type="checkbox"]{
                    position: absolute;
                    left: 0px; 
                    top: 4px;
                }
            }

            .yellow-btn{
                padding: 10px 20px 10px 20px;
            }

            @include media(768px){
                h4{                    
                    padding: 0;
                }
    
            }
        }

        .laststep-form-info{
            position: relative; 
            padding: 0;
            color: $b;

            span{
                font-size: 18px; 
                margin-bottom:40px; 
                display:block;

                &:after{
                    content: ''; 
                    display: block; 
                    width: 100px; 
                    height: 1px;
                    margin: 30px auto 0; 
                    background-color: #fff;

                    &:last-child{
                        margin-bottom:0px;

                        &:after{
                            display: none;
                        }
                    }                
                }                
            }

            &.bullet-list{
                ul{  
                    @include padding-all(0, 0, 25px, 0px);          
                    li{
                       &:before {
                            background-color: $b;                
                        }
                    }
                }  
            }
        }
    }
}

.top-header-info{
    color:$secondary-color;
    text-align: center;
    padding:0 10% 30px;

    .txt-info{
        h1{
            font-size: 72px;
            margin-bottom:80px;
        }
        p{
            font-size: 1.8em;
            font-weight: $medium;
        }
    }
    
    @include media(768px){
        padding:0;
        
        .txt-info{
            h1{
                font-size: 50px;
                margin-bottom:40px;
            }
            p{
                font-size: 1.6em;
            }
        }
    }
}

.txt-intro{   

    h4{
        line-height:30px;
    }
    p{
        font-weight: $medium;
        font-size: 18px;
    }
}

.she-software-help{
    .inner{
        background-color:$w;
        padding:50px 50px 30px;
        border-radius: 20px;

        @include media(768px){
            padding:30px 20px;
        }
    }
    .intro-info{
        h3{
            font-style: italic;
        }
        p{
            font-size: 18px;
        }
    }
    .owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    width: 6px;
                    height: 6px;
                    margin: 10px;
                }
                &:hover{
                    span{
                        background: #000;
                    }
                }
                &.active {
                    span {
                        background: #000 !important;
                    }
                }
            }
        }
    }    
}

.organisation-benefit{
    .inner{
        background-color:$w;
        padding:50px;
        border-radius: 20px;

        @include media(768px){
            padding:30px 20px;
        }
    }

    .intro-info{
        padding-bottom:50px;

        h4{
            font-weight:$medium;
            line-height:30px;
        }
        p{
            font-weight:$medium;
            line-height:30px;
            font-size: 20px;
        }
    } 

    .accordion{
        .accordion-box{
            .card-header{
                background-color: transparent;
                padding:15px 20px 15px 10px;
                position: relative;
                cursor: pointer;

                a{
                    color: #000;
                    &:hover{
                        color:#155c9b;
                    }
                }

                &:after {
                    font-family: 'FontAwesome';  
                    content: "\f068";
                    position: absolute;
                    right:10px;
                    top:10px;
                }
    
                &.collapsed{
                    &:after {
                        content: "\f067"; 
                    }
                }
            }
        }        
    }    
}

.stop-watch-section{
    text-align: center;    

    .txt-info{
        color:$w;    
        padding: 4% 10%;

        h3{
            font-size: 36px;
        }
        h4{
            font-size: 30px;
        }

        @include media(768px){
            padding: 20px 0 0;
        }
    }
}

// Fixed Background Images
.footer{
    text-align: center;
    color: #fff;
    @include padding-all(20px, null, 20px, null);
    font-weight:$medium;
    
    p{
        a{
            color:$w;
            text-decoration: underline;

            &:hover{
                text-decoration: none;
            }
        }
    } 
}

// Fixed Background Images
.trans-bg{
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color:inherit;       
    }
}
// Fixed Background Images
.full-bg{
    @include bgCover;    
    position: relative;
    
    &.trans-blue{
        &:before{
            @extend .trans-bg;
            background-color: rgba(4, 111, 174, .9);
        }
    }
    &.trans-blue2{
        &:before{
            @extend .trans-bg;
            background-color: rgba(24, 101, 147, .9);
        }
    }
    &.trans-blue3{
        &:before{
            @extend .trans-bg;
            background-color: rgba(2, 125, 197, .9);
        }
    }
}

.bullet-list{
    ul{ 
        @include margin(0);
        @include padding-all(0, 0, 15px, 0px);
        list-style: none;

        li{
           @include padding-all(5px, 0, 5px, 20px);
           position: relative; 

            &:before {
                content: "";                
                position: absolute;
                left: 0px;
                top: 14px;
                z-index: 9;
                width:6px;
                height: 6px;
                @include border-radius(100%);
                background-color: $b;                
            }
        }
    }  
}









































